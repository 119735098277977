@media screen and (max-width:767px) {
    /* #kt_app_content{
        padding-bottom: 0 !important;
    }
    
    #kt_app_header{
        display: none !important;
    }
    
    [data-kt-app-header-fixed-mobile=true] .app-wrapper{
        margin-top: 0;
    } */
    .msger-header{
        background: #e3371e;
        padding: 0 15px;
        height: 44px;
    }
}