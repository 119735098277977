// GLOBLE TAGS
.form-check {
  .form-check-label {
    color: $black;
  }

  .form-check-input {
    border-color: $black;

    &:checked {
      border-color: $ilead-primary;
      background-color: $ilead-primary;
    }
  }
}

.form-label {
  font-weight: $font-weight-normal;
}

.bg-primary {
  background-color: $ilead-primary !important;
}

.flex-1 {
  flex: 1;
}

.btn {
  &.btn-primary {
    background: $ilead-primary !important;

    &:hover,
    &:hover:not(.btn-active),
    &:active,
    &:active:not(.btn-active),
    &:focus,
    &:focus:not(.btn-active) {
      background: darken($ilead-primary, 10%) !important;
    }

    &.active {
      background: $ilead-secondary !important;
      border-color: $ilead-secondary !important;
    }
  }

  &.btn-link {

    &:hover,
    &:hover:not(.btn-active),
    &:active,
    &:active:not(.btn-active),
    &.active {
      color: darken($ilead-primary, 10%) !important;
    }
  }
}

.ilead-text {
  color: $ilead-primary !important;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.post-type {
  color: $post-type-color;
}

// LEFT SIDEBAR MENU
[data-kt-app-layout='dark-sidebar'] {
  .app-sidebar {
    @media screen and (min-width: $breakpoint-desktop) {
      // background-color: $app-sidebar-dark-bg-color;
      background-color: transparent;
      border-right: 0 !important;
    }

    .user-menu,
    .admin-menu {
      background-color: $app-sidebar-dark-bg-color;
      border-radius: 16px;

      .header-brand {
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
        padding-bottom: 10px;
      }
    }

    .menu {
      padding: 0;
      overflow-y: auto;

      .menu-item {
        .menu-link {
          &.active {
            background: $ilead-primary;
          }
        }
      }
    }
  }
}

[data-kt-app-sidebar-minimize='on'] .app-sidebar {
  @media screen and (min-width: $breakpoint-desktop) {

    .user-menu,
    .admin-menu {
      width: $app-sidebar-minimize-width - 11px;

      .select_hide {
        display: none;
      }

      .menu-title {
        white-space: nowrap;
      }

      h5 {
        display: none;
      }
    }

    .full-logo {
      display: none !important;
    }

    .small-logo {
      display: inline-block !important;
    }
  }

  #kt_app_sidebar_toggle {
    z-index: 1;
  }

  .menu-item .menu-link .menu-icon {
    width: 1.5rem;
  }

  .header-brand {
    &.ps-4 {
      padding-left: 0 !important;

      .user-details {
        display: none;
      }

      .btn-icon {
        display: none;
      }
    }
  }

}

// GI BI CONTAINER
.gibi-container {
  background: $white;
  padding: 0 1rem 1rem;
  top: 30px;

  .bigi-members {

    // overflow-x: auto;
    // display: flex;
    // flex-wrap: nowrap;
    .member-box {
      width: 107px;
      margin-right: $spacer - 0.375rem;
      background: $white;
      height: 107px;
      position: relative;
      overflow: hidden;
      border-radius: 20px 20px 0 20px;

      @media screen and (max-width: $breakpoint-mobile) {
        width: 100px;
        height: 100px;
      }

      .member-details {
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);

        h6 {
          font-size: 14px;
        }
      }
    }
  }
}

// POST FILTER WITH MOBILE VIEW
.post-filter {
  .main-filter {
    width: 100%;

    .newicon-filter {
      width: calc(100% - 44px);

      .indiana-scroll-container {
        display: flex;
        flex-direction: row;
      }
    }

    button.main-button {
      font-size: 0.8125rem;
      position: relative;
      min-width: 44px;
      min-height: 44px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;

      &.btn-primary {
        background: #e1e3ea !important;

        svg,
        svg path {
          fill: #3f4254;
        }
      }
    }
  }
}

// mobile filter
.mobile-filter {
  .filter-scroll-area {
    max-height: 350px;
    overflow-x: auto;

    .filter-item-icon {
      display: flex;
      margin-bottom: 8px;

      &>input[type="radio"] {
        display: none;
      }

      .form-check-label {
        border-radius: 4px;
        font-size: 16px;
        padding: 6px 15px 6px 10px;
        width: 100%;

        &:hover {
          background: rgb($ilead-primary, 1);
          color: #fff;

          svg,
          svg path {
            fill: $white
          }
        }
      }

      &>input[type=radio]:checked {
        &~.form-check-label {
          background: rgb($ilead-primary, 1);
          color: $white;

          svg,
          svg path {
            fill: $white
          }
        }
      }

      svg,
      svg path {
        fill: $ilead-primary
      }
    }
  }
}

// POST VIEW DESIGN
.post-listing {
  .infinite-scroll-component {
    overflow: inherit !important;
  }

  .post-card {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    position: relative;

    &.hidePost {
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background: #666;
        opacity: 0.5;
        border-radius: calc(0.625rem - 1px);
        pointer-events: none;
        z-index: 1;
      }
    }

    .card-header {
      border-bottom: 0;

      .card-toolbar {
        .post-type {
          position: absolute;
          right: 10px;
          top: 10px;

          &::before {
            position: absolute;
            content: '';
            width: 130px;
            height: 50px;
            background: rgb(#7239ea, 0.1);
            transform: rotate(45deg);
            right: -60px;
            top: -15px;
          }

          >div {
            z-index: 1;
            position: relative;

            svg,
            svg path {
              fill: rgb(#7239ea, 0.8);
            }
          }

          &.icaniwilltype {
            &::before {
              background: rgb($ilead-primary, 0.1);
            }

            >div {
              z-index: 1;
              position: relative;

              svg,
              svg path {
                fill: rgb($ilead-primary, 0.7);
              }
            }
          }
        }

        span {

          svg,
          svg path {
            fill: #000 !important;
          }
        }

        .post-action-dropdown {
          .btn-primary {
            background: transparent !important;
            color: #000;
            padding: 10px !important;
            width: 44px;
            height: 44px;

            &::after {
              display: none !important;
            }

            &:hover {
              background: #f5f5f5 !important;
            }
          }
        }
      }
    }

    .top-leader-profile {
      border: 2px solid $post-type-color;

      img {
        border: 2px solid $white;
      }
    }

    .thankx_image {
      img {
        border: 2px solid $ilead-border-color;
        object-fit: cover;
      }
    }

    .leaderlink {
      color: $ilead-primary;
    }

    .round-separator {
      background: $ilead-border-color;
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 100%;
    }

    .card-footer {
      // background: $post-dark-color;
      border-top: 1px solid $post-dark-color;

      .nav-item {
        .nav-link {
          max-height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $ilead-secondary;
          background: rgb($ilead-primary, 0.05);
          border-radius: 4.25rem;

          &:hover {
            background: darken($post-dark-color, 10%) !important;
            // border-radius: 0.25rem;
          }

          &.share-button {
            max-width: calc(100% - 40px);
          }

          i {
            font-size: 20px;
          }
        }
      }

      .dropdown-toggle {
        padding: 12px !important;

        &::after {
          display: none;
        }
      }


      .nav-item.action,
      .nav-link.action {
        &::after {
          // content: '';
          // position: absolute;
          // right: 0;
          // top: 50%;
          // transform: translateY(-50%);
          // width: 2px;
          // height: 15px;
          // background-color: $ilead-border-color;
        }
      }
    }

    .media {
      max-height: 600px;
      overflow: hidden;
    }

    &.hide-post {
      opacity: 0.3;
    }

    .winner-post {
      background-color: #efecec;
      background: radial-gradient(88.59% 132.88% at 49.76% 54.99%,
          #ffffff 28.42%,
          #f6f5f5 38.69%,
          #e5e3e3 52.41%,
          #cbc9c8 68.08%,
          #a9a9a9 85.09%,
          #a3a3a2 88.37%);
      overflow: hidden;
      position: relative;

      &:before {
        background-image: url('../../../../../public/media/ilead/rabbin.svg');
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: -30px;
        top: 0;
      }

      padding: 15px;

      .winner-title {
        font-size: 20px;
        font-weight: 900;
        font-family: "Muli", sans-serif;
        color: #000;
        position: relative;
        display: block;
        text-align: center;
        margin-bottom: 20px;

        .ribbon {
          margin: 0 auto;
          position: relative;
          background: #76ab25;
          color: #fff;
          display: inline-block;
          font-size: 15px;
          font-weight: 400;
          padding: 4px 30px;
          text-align: center;

          div:first-child,
          div:nth-child(2) {
            background-color: #000;
            position: absolute;
            left: -10px;
            bottom: -10px;
            z-index: -1;
            border: 10px solid transparent;
            border-right-color: #6c9c22;
            -webkit-animation: edge 500ms;
            -moz-animation: edge 500ms;
            -ms-animation: edge 500ms;
            animation: edge 500ms;
          }

          div:nth-child(2) {
            left: auto;
            right: -10px;
            border-right-color: transparent;
            border-left-color: #6c9c22;
          }

          div:nth-child(3),
          div:last-child {
            width: 10px;
            bottom: -10px;
            left: -11px;
            z-index: -2;
            border: 10px solid #84bf2a;
            border-left-color: transparent;
            -webkit-animation: back 600ms;
            -moz-animation: back 600ms;
            -ms-animation: back 600ms;
            animation: back 600ms;
            -webkit-transform-origin: 100% 0;
            -moz-transform-origin: 100% 0;
            -ms-transform-origin: 100% 0;
            transform-origin: 100% 0;
            position: absolute;
          }

          div:last-child {
            bottom: -10px px;
            left: auto;
            right: -11px;
            border: 11px solid #84bf2a;
            border-right-color: transparent;
            -webkit-transform-origin: 0 0;
            -moz-transform-origin: 0 0;
            -ms-transform-origin: 0 0;
            transform-origin: 0 0;
          }
        }
      }

      .winner-wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        .leader {
          display: flex;
          align-items: center;
          flex-direction: column;
          flex-basis: 50%;
          margin-bottom: 15px;

          &:nth-child(2n) {
            padding-right: 8px;
          }

          &:nth-child(3n) {
            padding-left: 8px;
          }

          &:first-child {
            flex-basis: 100%;
            padding: 0;
          }

          &:not(:first-child) {
            .leader-details {
              width: 100%;
            }
          }

          .leader-avatar {
            width: 74px;
            height: 74px;
            border-radius: 100%;
            overflow: hidden;
            border: 2px solid #84bf2a;
            position: relative;
            z-index: 1;
          }

          .leader-skwdiv {
            background: rgba(0, 0, 0, 0.2);
            width: 74px;
            height: 40px;
            filter: blur(8px);
            -webkit-filter: blur(7px);
            border-radius: 100%;
            position: absolute;
            top: 24px;
            left: calc(50% - 10px);
            -webkit-transform: skew(2.8669rad);
            transform: skew(2.8669rad);
          }

          .leader-details {
            padding: 50px 20px 20px;
            margin-top: -30px;
            border-radius: 4px;
            background: rgba(241, 241, 241, 0.8);
            box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
            -webkit-backdrop-filter: blur(116px);
            backdrop-filter: blur(116px);
            flex: 1;

            h4 {
              font-size: 17px;
              color: #000;
              font-weight: 600;
              margin-bottom: 0;
              text-align: center;
            }

            h3 {
              font-size: 24px;
              font-weight: bold;
              color: #a52958;
              margin-bottom: 0;
              text-align: center;
            }
          }
        }
      }
    }


    .pinned__image {
      width: 80px;
      height: 100px;
      overflow: hidden;
      border-radius: 4px;
      left: 12px;
      top: 12px;
      .winner-post{
        padding: 5px;
        .winner-title{
          display: none !important;
        }
        .leader-details{
          display: none;
        }
        .winner-wrapper{
          .leader{
            padding-right: 4px !important;
            margin-bottom: 4px;
            padding-left: 0 !important;
            .leader-avatar{
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      &.birthday{
        width: 80px;
        height: 80px;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
      }

      @media screen and (max-width: $breakpoint-mobile) {}
    }

    .pinned_content {
      padding-left: 95px;

      &.only-content {
        padding-left: 0px;
      }
    }
  }

  .new-post-added {
    position: fixed;
    top: 84px;
    z-index: 9;
    background-color: $ilead-primary !important;
    border-radius: 50px;
    padding: 6px 24px !important;
    text-align: center;
    color: $white;
  }
}

// COMMENT VIEW
#post_comment_view {
  .comment-body {
    .commnet_box_right {
      background-color: $post-dark-color;
      border-radius: 0 8px 8px;
      padding: 1rem;

      .round-separator {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        color: $ilead-separator-color;
      }
    }
  }
}

//ADD POST
.add_post_button {
  position: fixed;
  bottom: 20px;
  border-radius: 100%;
  border: 2px solid $white;
  box-shadow: 2px 1px 15px rgba(0, 0, 0, 0.4);
  z-index: 99;
  margin-right: 15px;
}

.ilead-default-modal {
  .modal-header {
    background: $ilead-primary;

    * {
      color: white;
    }

    button.btn-close:hover {
      background-color: white;
      border-radius: 100%;
    }
  }

  .modal-body {
    &.write-post-wrapper {
      .writepost-btn-group {
        position: relative;
        background: rgba(242, 101, 34, 0.1);
        width: 100%;
        border-radius: 100px;
        overflow: hidden;

        button {
          margin: 0 !important;
          padding: 2px !important;
          background: transparent;
          box-shadow: none;
          color: $black;
          text-transform: inherit;
          font-weight: 500;
          border-radius: 100px;
          height: 44px;
          border: 0;

          &:hover,
          &:active,
          &:focus,
          &.active {
            background: $ilead-primary;
            color: $white;
          }
        }
      }

      .writepost-button {
        padding: 15px 15px !important;
        text-align: center;
        background-color: $white;
        border: 1px solid $ilead-border-color !important;
        border-radius: 15px 15px 0 15px;
        height: 100%;
        font-size: 14px;
      }

      .tab-content {
        padding-top: 30px;
      }

      .ref-icon img {
        height: 120px;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      .gratitude-title {
        font-size: 16px;
        border: 1px dotted $ilead-primary;
        border-radius: 5px;
        max-width: 80%;
        margin: 0 auto;
        margin-bottom: 15px;
        background-color: rgb($ilead-primary, 0.05);
      }
    }

    .leader_list_scroll_wrapper {
      height: calc(100vh - 380px);
      overflow-y: auto;
    }

    .name-wrapper {
      .name-title {
        font-weight: 500;
      }

      h2 {
        font-size: 16px;
        padding: 10px 10px;
        background-color: #eee;
        border-radius: 5px;
      }
    }

    .whatwentwellbox {
      border: 1px solid $ilead-input-border-color;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    }

    .goal_box {
      border: 1px solid #e0e0e0;
      border-radius: 8px 8px 8px 0;
    }
  }
}

// CUSTOM INPUT
.custom-text-area {
  padding: 0;
  border: none;
  margin-top: 0;
  resize: none;
  font-size: 14px;
  outline: 0;
  border-radius: 0;
  padding-top: 7px;

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

// CAROUSEL SLIDER
.carousel {
  .carousel-indicators {
    [data-bs-target] {
      width: 12px;
      height: 12px;
      border-radius: 100%;
    }

    &.active {
      background-color: $ilead-primary;
    }
  }
}

// ANIMATION
.go4268192979 {
  background: url('../../../../../public/media/ilead/animation.png') no-repeat !important;
  height: 40px !important;
  width: 40px !important;
}

.go2484888251 {
  background: url('../../../../../public/media/ilead/animation.png') no-repeat !important;
  background-position: -1119px 2px !important;
  height: 40px !important;
  width: 40px !important;
}

// SCROLLING EFFECT CSS
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.react-horizontal-scrolling-menu--wrapper {
  position: relative;

  .react-horizontal-scrolling-menu--arrow-left,
  .react-horizontal-scrolling-menu--arrow-right {
    position: absolute;
    left: -24px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;

    Button {
      width: 44px;
      height: 44px;
      background: $white !important;
      box-shadow: 2px 1px 15px rgba(0, 0, 0, 0.4) !important;
      border-radius: 100%;
    }

    @media screen and (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  .react-horizontal-scrolling-menu--arrow-right {
    left: inherit;
    right: -24px;
  }
}


.ilead-input {
  min-height: calc(2.75rem + 8px);
  border: 0;
  border: 0;
  border-bottom: 1px solid $ilead-input-border-color;
  border-radius: 0.25rem;
  border-radius: 0;
  color: $ilead-secondary;
  font-weight: 400;
}

.input-group {
  .ilead-input-group {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
}

// CHAT MODULE
.chat-view {
  background: $white;
  padding: 0;
  border-radius: 0.625rem 0.625rem 0 0;

  &.desktop {
    max-width: 580px;
    width: 100%;
    height: calc(100vh - 275px);
    top: 275px;
    // position: fixed;
    bottom: 0;
    padding: 1px;

    .chat__secondary-view {
      width: 100%;

      .detailpane__header {
        .header__close {
          background: $ilead-primary;
        }
      }
    }
  }

  &.full-width {
    height: calc(100vh - 70px);

    @media screen and (max-width: $breakpoint-mobile) {
      padding-top: 70px;
    }

    >.msger-header {
      +i {
        top: 26px !important;

        @media screen and (max-width: $breakpoint-mobile) {
          top: 72px !important;
        }
      }
    }

    .cometchat {
      @media screen and (max-width: $breakpoint-mobile) {
        height: calc(100vh - 155px) !important;
      }
    }
  }

  &.desktop1 {
    max-width: 580px;
    width: 100%;
    height: calc(100vh - 300px);
    position: absolute;
    bottom: 0;
  }

  .nav-pills {
    border-radius: 8px;
    background: #eeeef0;
    padding: 4px;
    margin: 15px 15px 0;

    .nav-link {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 12px;

      &.active {
        background: $ilead-primary;
        color: $white;

        i {
          color: $white;
        }
      }
    }
  }

  .cometchat {
    border: 0;

    .unified__sidebar {
      border: 0;
      box-shadow: none !important;

      .contacts__search,
      .groups__search {
        margin: 15px;
        min-height: 44px;
      }

      .contacts__list,
      .groups__list {
        margin: 15px 15px 0;
      }

      .contacts__list__alphabet-filter {
        display: none;
      }

      .list__item {
        padding: 8px 0;

        .list__item__thumbnail {
          width: 50px;
          height: 50px;
          position: relative;

          img {
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
          }

          .presence {
            top: 35px;
          }
        }

        .item__details__desc {
          border-bottom: 0;
        }

        &:hover,
        &.css-90flt1-CometChatUserListItem {
          background: transparent;
        }
      }
    }

    .main__chat {
      .chat__header {
        padding: 15px;

        .chat__sidebar-menu {
          visibility: hidden;
          display: block;
        }

        .chat__options {
          i {
            background: $ilead-primary;
          }

          .option__viewdetail-user {
            display: none;
          }
        }
      }

      .chat__list {
        background: #fff;

        @media screen and (max-width: $breakpoint-mobile) {
          background: #f7f7f9;
        }

        .sender__message__container {
          .message__txt__wrapper {
            background: #fff;
            border-radius: 12px 12px 0 12px;
            background: #dedee0;
            color: #000;
            box-shadow: rgba(20, 20, 20, 0.04) 0 3px 1px, rgba(20, 20, 20, 0.12) 0 3px 8px;
            font-weight: 400;

            p {
              font-size: 16px;
            }
          }

          .css-1kybrqw {
            background-color: $ilead-primary;
          }
        }

        .receiver__message__container {
          .message__txt__wrapper {
            background: #fff;
            border-radius: 0 12px 12px;
            box-shadow: rgba(20, 20, 20, 0.04) 0 3px 1px, rgba(20, 20, 20, 0.12) 0 3px 8px;
            font-weight: 400;

            p {
              font-size: 16px;
            }
          }
        }

        .message__timestamp {
          color: #808082;
        }
      }

      .chat__composer {
        padding: 0 15px;
        min-height: 48px;
        border-top: 1px solid #eaeaea;

        @media screen and (max-width: $breakpoint-mobile) {
          background: #f7f7f9;
          border-top: 0;
        }

        .composer__input {
          min-height: 48px;
          padding: 10px 0;

          .input__inner {
            flex-direction: row;
            min-height: 48px;
            height: 48px;
            border: 0;
            background: transparent;
            line-height: 16px;

            .input__sticky {
              background: transparent;
              border-top: 0;
              padding: 0;

              .button__send {
                background: $ilead-primary;
                width: 48px;
                height: 48px;
                border-radius: 4px;

                i {
                  background: #fff;
                }
              }

              .attachment__icon {
                background: #fff;
                border-radius: 0 4px 4px 0;
                padding: 24px;
              }
            }

            .icaniwilbtn {
              padding-right: 8px;

              button {
                background: rgb($ilead-primary, 0.1);
                border-radius: 4px;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .input__message-input {
              background: white;
              min-height: 48px;
              border-radius: 4px 0 0 4px;
              line-height: 16px;

              &:empty:before {
                color: rgb(0, 0, 0);
              }
            }
          }
        }
      }

      .message_pane__banner {
        background: transparent;

        .message_pane__unread_banner__msg {
          background: #fff;
          border-radius: 40px;
          border: 0;
          padding: 5px 15px;
        }
      }
    }

    .chats,
    .groups,
    .userinfo,
    .contacts {
      height: 100%;
    }
  }

  &.chat-sidebar {
    .chatSidebar-main {
      width: 100%;
      right: -600px;
      position: fixed;
      height: 100%;
      background: #fff;
      max-width: 600px;
      top: 0;
      transition: 0.5s all ease-in-out;
      z-index: 9;
      box-shadow: -6px -4px 23px rgba(0, 0, 0, 0.5);
      opacity: 0;

      .chatSidebar {
        padding-top: 15px;
      }

      .nav-pills {
        margin-top: 0 !important;
      }

      &.active {
        right: -0px;
        opacity: 1;

        &::after {
          background: rgba(0, 0, 0, 0.2);
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          content: '';
          z-index: -1;
        }
      }
    }
  }
}

// SEARCH LEADER PAGE
.top__filter {
  @media screen and (max-width: $breakpoint-mobile) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

  .form-select {
    box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.10);
    border: 1px solid #fff;
    border-radius: 8px !important;
    width: 120px;

    &:hover,
    &:focus {
      border: 1px solid $ilead-primary;
      box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.10);
    }
  }

  .search__input__group {
    min-width: 200px;
    flex-shrink: 0;
    color: #212121;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.10);
    border: 1px solid #fff;

    &.tyca-filter {
      min-width: auto;
    }

    .top__search__input {
      border: 0;
      padding-left: 0;
    }

    &:hover,
    &:focus {
      border: 1px solid $ilead-primary;
      box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.10);
    }
  }
}


.five-div {
  @media screen and (min-width: 1400px) {
    .col-xl-3 {
      width: 20%;
    }
  }
}

.ilead__card {
  border-radius: 6px !important;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.20);

  .chapter__name {
    font-weight: 400;

    &.trustees {
      color: #6367E8;
    }
  }

  .leader__email .symbol-label {
    background: rgba($color: #0094E7, $alpha: 0.1);

    i {
      color: #0094E7;
    }
  }

  .leader__mobile .symbol-label {
    background: rgba($color: #009870, $alpha: 0.1);

    i {
      color: #009870;
    }
  }

  .leader__website .symbol-label {
    background: rgba($color: #E3371E, $alpha: 0.1);

    i {
      color: #E3371E;
    }
  }

  &.main__leaders {
    .symbol-100 {
      position: relative;
      padding-bottom: 100%;
      width: 100%;

      @media screen and (max-width: $breakpoint-mobile) {
        width: 100px;
        padding-bottom: 0;
      }

      img {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;

        // @media screen and (max-width: $breakpoint-mobile) {
        //   position: relative;
        // }
      }
    }

    .mobile-border-none {
      @media screen and (max-width: $breakpoint-mobile) {
        border: 0 !important;
      }
    }
  }
}

.song-div {
  height: 114px;

  .song-cover {
    padding: 0;
    width: 110px;
    height: 114px;
  }

  .song-audio {
    padding-left: 130px;
  }
}

.library-row {
  margin-bottom: 30px;
  margin-top: 30px;
}

.twoline-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fiveline-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-blue {
  background-color: #0094E7 !important;
}

.business__profile__doc {
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 0.5rem;
  max-width: 200px;

  a {
    display: flex;
    flex-direction: column;

    img {
      width: 100px;
      clear: both;
    }
  }

}

.infinite-scroll-component {}

.mobile-padding {
  @media screen and (max-width: $breakpoint-mobile) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

// RESPONSIVE MOBILE
@media screen and (max-width: $breakpoint-mobile) {
  .fs-13 {
    font-size: 11px;
  }

  .fs-14 {
    font-size: 12px;
  }

  .card {
    border-radius: 0;
  }

  .gibi-container {
    top: 0;

    .nav-tabs {
      top: -1px !important;
      position: fixed !important;
      z-index: 99;
    }
  }

  .mobile-noMargin {
    margin-left: 0;
    margin-right: 0;

    .col-lg-6,
    .col-xl-6 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .auth-bg {
    background-image: none !important;

    .login-card {
      box-shadow: none !important;
    }
  }

  #kt_app_content {
    padding-top: 0 !important;

    #kt_app_content_container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  #kt_app_header {
    border-bottom: 1px solid $ilead-border-color;
  }

  .nav-line-tabs {
    padding-top: 9px !important;

    .nav-item .nav-link {
      padding: 0.5rem 0.625rem;
      margin-right: 0 !important;
      text-align: center;
    }

    // Active & Hover States
    .nav-item .nav-link.active,
    .nav-item.show .nav-link,
    .nav-item .nav-link:hover:not(.disabled) {
      border: 1px solid $ilead-border-color;
      border-bottom: 2px solid $white;
      background: $white;
    }
  }

  .mobile-filter {
    width: 100% !important;
    top: 100px !important;
    transform: inherit !important;
    height: calc(100vh - 100px);

    .bg-white {
      border-radius: 15px 15px 0 0;
    }

    .filter-scroll-area {
      max-height: calc(100vh - 235px);
      overflow: scroll;
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      top: -100px;
      z-index: -1;
    }
  }

  .post-listing .post-card .card-footer .nav-item .nav-link i {
    font-size: 16px;
  }

  .modal-fullscreen-md-down {
    height: calc(100% - 64px);
    margin-top: 14px;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }

  #ilead_app_mobile_footer {
    position: fixed;
    width: 100%;
    border-top: 1px solid $ilead-border-color;
    background: $white;
    bottom: 0;
    z-index: 9;

    .w-20 {
      width: 20%;
      &.active{
        color: $ilead-primary !important;
        * {
          color: $ilead-primary !important;
        }
      }
    }
  }

  [data-kt-app-header-fixed-mobile='true'] .app-header {
    z-index: 9;
  }

  .add_post_button {
    bottom: 84px;
    left: inherit;
    right: 20px;
  }

  .ilead-default-modal {
    .modal-fullscreen-md-down {
      margin-top: 0;
      height: 100%;
      border-radius: 0;
    }

    .modal-content {
      background: $ilead-modal-content;
    }

    .modal-body {
      border-radius: 40px 0 0 0;
      background-color: $white;
      width: calc(100% - 15px);
      background: $white;
      margin-left: 15px;
    }
  }

  .chat-view {
    border-radius: 0;
    padding: 1px 0 0;
    background: white;

    .nav-pills {
      margin: 15px 15px 0;
    }

    .cometchat {
      .unified__sidebar {

        >.search,
        .chats,
        .groups,
        .userinfo,
        .contacts__list {
          height: 100%;
        }

        .contacts__search,
        .groups__search {
          margin: 15px;
        }
      }
    }
  }
}

// RESPONSIVE 1024PX
@media screen and (min-width: $breakpoint-desktop) {

  .dashboard-wrapper,
  .gibi-container {
    max-width: 600px;
  }
}

.main-filter {

  button:focus.btn.btn-primary.main-button,
  button.active.btn.btn-primary.main-button,
  button:hover.btn.btn-primary.main-button {
    background-color: rgba(0, 148, 231, 1) !important;

    svg,
    svg path {
      fill: $white;
    }
  }
}

.icaniwill-input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

.icaniwill-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.post-listing .post-card .card-footer .nav-link.action::after {
  background-color: transparent !important;
}

.admin-menu select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.carousel-indicators [data-bs-target] {
  background: #000;
  border-top: 0 !important;
  border-bottom: 0 !important;
}


.carousel-indicators button {
  width: 12px;
  height: 12px;
}

@media screen and (min-width:1200px) {
  .fixed-div {
    position: fixed;
    max-width: 445px;
    height: calc(100vh - 30px);
  }
}

@media screen and (min-width:1400px) {
  .fixed-div {
    position: fixed;
    min-width: 570px;
    max-width: 570px;
  }
}

@media screen and (min-width:768px) {
  .right-wrapper {
    z-index: 9;
  }
}

.symbol img {
  object-fit: cover;
}

.chat-button {
  position: fixed;
}

.blink{
  animation: blink 1s infinite;
}

@keyframes blink{
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}