/* Styling for the webcam pop-up container */
.webcam-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Styling for the webcam pop-up content */
.webcam-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styling for the webcam video stream */
.webcam-popup-content video {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Styling for the webcam buttons */
.webcam-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Styling for the webcam capture button */
.webcam-buttons button:first-child {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}
 
/* Styling for the webcam cancel button */
.webcam-buttons button:last-child {
  background-color: #ccc;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
/* Styling for the webcam pop-up container */
.webcam-popup {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
display: flex;
justify-content: center;
align-items: center;
z-index: 9999; /* Ensure the pop-up is above other elements */
}

/* Styling for the webcam pop-up content */
.webcam-popup-content {
background-color: #fff;
padding: 20px;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styling for the webcam video stream */
.webcam-popup-content video {
width: 100%;
height: auto;
border-radius: 5px;
}

/* Styling for the webcam buttons */
.webcam-buttons {
display: flex;
justify-content: center;
margin-top: 20px;
}

/* Styling for the webcam capture button */
.webcam-buttons button:first-child {
background-color: #007bff;
color: #fff;
border: none;
padding: 10px 20px;
margin-right: 10px;
border-radius: 5px;
cursor: pointer;
}

/* Styling for the webcam cancel button */
.webcam-buttons button:last-child {
background-color: #ccc;
color: #000;
border: none;
padding: 10px 20px;
border-radius: 5px;
cursor: pointer;
}
