.webcam-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.cancel-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 25px; /* Positioned at the top-right corner */
  cursor: pointer;
}

/* Styles for the "Switch Camera" button */
.toggle-button {
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 5px;

  border-radius: 25px;
  bottom: 87px;
  right: 15px; /* Positioned at the bottom-right corner */
  cursor: pointer;
  /* Add any other styles you need for the button */
}

.webcam-popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.webcam-popup-content video {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.webcam-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.webcam-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 5px 10px; */
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.webcam-buttons button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .webcam-popup-content {
    max-width: 100%;
  }
}

.ilead-input1 {
  background: #fff;
  height: 30px;
  min-width: 150px;
}

.ilead-input1::before {
  content: 'HH:MM';
  color: #9d9d9d;
  position: absolute;
  background: #fff;
  height: 18px;
  pointer-events: none; /* Allow clicking through the pseudo-element */
}

.ilead-input1.time-selected1::before {
  content: '';
}
