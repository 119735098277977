// Invitation History
@media screen and (max-width: 767px) {
  h1.inv-hst {

    margin-top: 0px;
    margin-left: 0;
    background: $ilead-primary;
    padding: 10px 10px 10px 20px;
    color: #fff !important;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
  }
  .table-sub-hdr {display: none !important;}
  .fdbk-user-icon {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .user-detail>div {
    text-align: center;
    justify-content: center !important;
  }

  .user-detail a {
    font-size: 14px !important;
  }

  .mob-view-pl-pr {
    padding-left: 15px !important;
    padding-right: 15px !important
  }

  .bg-primary {
    background: $ilead-primary !important;
  }

  .invitation-history {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mob-vie-card {
    background-color: $post-dark-color !important;
  }

}

@media screen and (max-width:$breakpoint-desktop) {
  .mb-view-bg-trans {
    background-color: transparent !important;
  }
}

.invitation-history table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;

  caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;

    @media screen and (max-width: $breakpoint-mobile) {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
      padding: 0;
      margin-top: 25px;
    }
  }

  @media screen and (max-width: $breakpoint-mobile) {
    td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 14px;
      text-align: right;
      border-bottom: 1px solid $ilead-border-color !important;

      &::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }

      &:first-child {
        font-weight: 600;
        color: #fff !important;
        padding-left: 10px !important;
        background-color: $ilead-primary !important;
        border-bottom: 3px solid $ilead-primary !important;
      }

      &:last-child {
        border-bottom: 0;
        padding-right: 10px !important;
      }
    }
  }

  th,
  td {
    padding: 0.625em;
    text-align: center;

    @media screen and (max-width: $breakpoint-mobile) {
      text-align: right;
    }
  }

  th {
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 15px 10px;
    font-weight: 600;
    color: #fff !important;
    background-color: $ilead-primary !important;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    border: 0 !important;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #ebebebbf !important;
  color: var(--bs-table-striped-color);
}

.reset-date-psw .modal-header {
  background-color: #d54d0d;
  color: #fff;
  font-weight: 600;
}

.invitation-history .btn-primary {
  background-color: #0d6efd !important;
}

// Library

.filter-div {
  min-width: 300px;

  .form-select-sm {
    background-color: transparent;
  }

  h4 {
    color: $ilead-primary;
    font-size: 14px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 25px !important;
    padding-top: 15px;
    min-width: 90%;
    width: 90%;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .library-filter {
    justify-content: flex-start;
    align-items: start;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.library {
  h4 {
    color: $ilead-primary;
    font-size: 18px;
    padding-left: 10px;
  }

  h5 {
    background-color: #000;
    margin: 0;
    padding: 12px 15px;
    color: $ilead-primary;
  }

  iframe {
    width: 96% !important;
    height: 325px !important;
  }

  iframe body>img {
    max-width: 100% !important;
    max-height: 100% !important;
  }

  img {
    max-width: 100%;
  }

  p.ileade-lyrics {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    text-align: left;
    padding-left: 10px;
  }

  .song-div {
    background-color: $post-dark-color;
    border: $ilead-border-color;
    border-radius: 16px;

    .col-3 img {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }

  .bg-dark {
    text-align: center;
    background: #000 !important;
  }
}

// ICIWF

.iciwf {
  h1 {
    color: $ilead-primary;
    font-weight: 700;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .thumbnail-box {
    height: 220px !important;

    img.thumbnail-img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }

    @media screen and (max-width: $breakpoint-mobile) {
      text-align: center;
    }
  }

  .defaultuser p {
    display: block;
    font-size: 15px;
    margin-bottom: 7px;
    color: #000;
    text-align: center;
    padding: 0;
  }

  button.title-name {
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    color: #337ab7;
    border: 0;
    background: transparent;

    @media screen and (max-width: $breakpoint-mobile) {
      width: 100%;
      text-align: center;
    }
  }

  h6 {
    font-size: 18px;
    color: #d54d0d;
    text-align: left;
    font-weight: 400;
    padding: 0px 10px;

    @media screen and (max-width: $breakpoint-mobile) {
      text-align: center;
    }
  }

  p {
    font-size: 15px;
    color: #161616;
    text-align: left;
    font-weight: 400;
    padding: 0px 10px;
    line-height: 24px;
  }
}

.search-group {
  position: relative;

  input {
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid $ilead-border-color;

    &:focus {
      border-color: $ilead-primary;
    }
  }

  button {
    position: absolute;
    right: 15px;
    top: 13px;
    border: 0;
    background-color: transparent;
  }

  label {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .form-control:focus~label {
    top: -5px;
    color: $ilead-primary;
    transition: 0.6s;
  }
}

// Search Leader

.search-ldr {
  .search-ldr-icon img {
    border-radius: 50%;
    max-width: 100%;

    @media screen and (max-width: $breakpoint-desktop) {
      width: 80px;
      max-width: 80px;
    }
  }

  @media screen and (max-width: $breakpoint-desktop) {
    .search-ldr-icon {
      text-align: center;
    }
  }

  .search-ldr-name {
    @media screen and (max-width: $breakpoint-desktop) {

      h3,
      p {
        text-align: center !important;
      }
    }
  }

  @media screen and (max-width: $breakpoint-desktop) {
    .col-lg-4 {
      margin-bottom: 15px;
    }
  }

  .eye-icon {
    position: absolute;
    right: 21px;
    color: #fff;
    font-size: 16px;
    top: 12px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    background-color: #222222;
    padding: 6px 7px;
    line-height: 17px;
    border-radius: 50%;
    transition: 0.6s;

    &:hover {
      background-color: $ilead-primary;
      transition: 0.6s;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .search-ldr {
    background-color: $post-dark-color !important;

    .col-lg-4 {
      border-radius: 16px;
      padding: 15px !important;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .mob-vie-card {
    background-color: $post-dark-color !important;
    margin-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &>div:first-child {
      background-color: #fff !important;
    }
  }
}

// Leader Profile

.leader-view {
  .leder-info {
    justify-content: start;
    height: 100%;

    img {
      border-radius: 50%;
      max-width: 100%;
    }

    @media screen and (max-width: $breakpoint-mobile) {
      border-radius: 16px;

      img {
        max-width: 100px;
        width: 100px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-mobile) {
    .col-md-8 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    p {
      text-align: left !important;
      margin-bottom: 12px !important;
    }

    h4 {
      margin-bottom: 30px;
    }

    margin-bottom: 15px !important;
    margin-top: 15px !important;
  }
}

.bussiness-info {
  border-radius: 16px !important;

  >h3 {
    border-bottom: 1px solid $ilead-secondary;
    margin-bottom: 15px;
    padding-bottom: 12px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    .col-md-6 {
      margin-bottom: 20px !important;
    }

    .row {
      margin-bottom: 0;
    }

    hr {
      display: none;
    }

    h4 {
      margin-bottom: 0px !important;
    }
  }

  .card {
    border-radius: 16px !important;
  }
}

.leader-perform {
  .card {
    border-radius: 16px !important;
  }

  .thankyou {
    background-color: $ilead-primary;
    text-align: center;
    border-radius: 0 0 4px 4px;
  }

  .leader_box {
    height: 130px;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 4px 4px 0 0;
    padding: 30px 10px 10px 10px;
    text-align: center;
    margin-bottom: 20px;
  }

  .leader_box button {
    text-align: center;
    display: block;
    color: #f26522;
    border: 2px dashed #f26522;
    height: 70px;
    width: 70px;
    line-height: 70px;
    margin: 0 auto;
    font-size: 20px;
    border-radius: 50%;
  }

  .leader_box button:hover {
    color: #000;
    border: 2px dashed #000;
  }

  .leader_box .modal-header {
    background-color: #f26522;
  }

  .leader_box h4 {
    color: #fff;
    text-align: left;
  }
}

.buss-card {
  h4 {
    border-bottom: $ilead-sidebar-dark-bg-color 1px solid;
    padding-bottom: 10px;
    margin-bottom: 20px !important;
    margin-bottom: 25px;
  }

  img {
    max-width: 100%;
  }
}

.buss-pro img {
  max-width: 100px;
}

.upld-icon img {
  max-width: 50px;
  cursor: pointer;
}

.leader-counter-modal {
  .modal-header {
    background-color: #f26522;

    button.close {
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
      -webkit-appearance: none;
    }
  }

  .dataTables_filter input,
  .dataTables_length select {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    .invitation-history table tr:first-child {
      margin-top: 0 !important;
    }
  }

  .invitation-history {
    border: 1px solid $ilead-modal-content !important;
  }
}

// Feedback and Query

.feedback {
  h4 {
    line-height: 30px;
  }

  textarea:focus {
    outline: none !important;
  }

  .fdbk-icon img {
    width: 45px;
  }

  .document {
    transform: rotatey(165deg);
  }
}

// Directory

.direciry-all,
.tyca-card-view {
  text-align: center;

  .all-user {
    background-color: transparent !important;

    .col-md-4 {
      background-color: #fff !important;
      padding: 25px 0px;

      @media screen and (max-width: $breakpoint-mobile) {
        border-radius: 16px;
        margin-bottom: 20px;
        padding: 15px 0px;
        position: relative;
      }

      .eye-icon {
        position: absolute;
        right: 40px;
        color: #fff;
        font-size: 16px;
        top: 16px;
        height: 30px;
        width: 30px;
        cursor: pointer;
        background-color: #222222;
        padding: 6px 7px;
        line-height: 17px;
        border-radius: 50%;
        transition: 0.6s;

        &:hover {
          background-color: $ilead-primary;
          transition: 0.6s;
        }
      }

      p {
        margin-bottom: 5px !important;
        font-size: 14px;
      }
    }

    .thumbnail-box {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;

      img {
        border-radius: 50% !important;
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding-left: 15px !important;
    padding-right: 5px !important;
    padding-top: 5px !important
  }

  @media screen and (max-width:$breakpoint-mobile) {
    >div:first-child {
      background-color: transparent !important;
    }

  }
}

.grp-facltr {
  .thumbnail-box {
    width: 150px;

    img {
      max-width: 100%;
      border-radius: 6px;
    }

    @media screen and (max-width: $breakpoint-mobile) {
      width: 100px;
      height: 100px;
    }

  }

  h3 {
    color: $ilead-primary;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 15px !important;
    margin-right: 5px !important;
    border-radius: 16px;
  }

  @media screen and (max-width: 480px) {
    .col-9 {
      padding-left: 50px;
    }

  }

  .col-2 {
    max-width: 180px !important;
    width: 180px;

    @media screen and (max-width: $breakpoint-mobile) {
      max-width: 110px !important;
      width: 110px;
    }
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;

    a {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    i {
      margin-right: 5px;
    }
  }


}

.dir-filter {
  .form-select-sm {
    border: 0 !important
  }

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 15px !important;
  }
}


.tyca-hdr {

  .tyca-filter {
    .col-md-4 {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;

      h4 {
        color: $ilead-primary;
      }

      @media screen and (max-width: $breakpoint-mobile) {
        text-align: left;
        padding-left: 0;
        margin-top: 15px;
      }
    }

    @media screen and (max-width: $breakpoint-mobile) {
      margin-left: 0 !important;
      width: 100%;
      max-width: 100% !important;
    }
  }

  @media screen and (max-width: $breakpoint-mobile) {
    >div:first-child {
      background-color: transparent !important;
    }
  }
}

.no-presentation h3 {
  color: $ilead-primary;
}

.tyca-card-view {
  .all-user {
    .col-md-6 {
      position: relative;
      margin-bottom: 20px !important;
      border: 1px solid $post-dark-color ;

      h3 {
        color: $ilead-primary;
        text-align: left !important;
        padding-right: 40px;
      }

      @media screen and (max-width: $breakpoint-mobile) {
        padding: 25px 25px !important;
        background-color: #fff !important;

        h3 {
          text-align: left !important;
        }

        margin-bottom: 20px !important;
        border:none !important;
        border-radius: 12px;
      }

      .beta-btn {
        text-align: left !important;
      }
    }

    .card-icon,
    .eye-icon {
      padding: 0px 0px 0px 5px !important;
      line-height: 15px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      font-size: 18px !important;
      width: 40px !important;
      height: 40px !important;

      i {
        font-size: 18px !important;
      }
    }

    .eye-icon {
      position: absolute !important;
      right: 15px !important;
      top: 20px !important;
      padding-left: 0 !important;
    }

  }

  @media screen and (max-width:$breakpoint-mobile) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;

    >div:first-child {
      background-color: transparent !important;
    }
  }

}


// Index 

ul.index-tab {
  background-color: $ilead-primary !important;

  li {
    width: 250px !important;
    text-align: center !important;
    background-color: $ilead-primary !important;

    button {
      background-color: $ilead-primary !important;
      color: #f5f5f5 !important;
      padding: 12px;
      margin: 0 15px !important;
      border-radius: 0 !important;
      width: 50%;
      border: 0 !important;
    }

    .nav-link.active {
      border: 0 !important;
      border-bottom: 1px solid #fff !important;
    }

    @media screen and (max-width:$breakpoint-mobile) {
      width: 50% !important;
    }
  }

  @media screen and (max-width:$breakpoint-mobile) {
    display: flex;

    button {
      width: 100% !important;
    }
  }

}

.index-hdr {

  >h4 {
    padding-left: 10px !important;

    @media screen and (max-width:$breakpoint-mobile) {
      padding-left: 20px !important;
    }

  }

  background-color: #fff;

  .index-filter {
    min-width: inherit !important;

    .fltr-lbl {
      h4 {
        color: $ilead-primary !important;
      }
    }

    @media screen and (max-width:$breakpoint-mobile) {
      margin-left: 10px !important;
      padding-top: 0px;
      width: 100%;
      padding-right: 20px !important;


      .card {
        padding: 0 !important;
      }

    }
  }


  @media screen and (max-width:$breakpoint-mobile) {

    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
    padding-top: 20px !important;
    margin-top: 20px !important;
    border-radius: 16px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .tyca-hdr {
      padding-bottom: 20px !important;
    }
  }

  .growth-listing {
    .col-2 {
      width: 100px !important;

      img {
        border-radius: 50% !important;
        max-width: 100%;
      }
    }

    .col-7 {
      padding-top: 20px;
      padding-left: 20px !important;

      @media screen and (max-width:$breakpoint-mobile) {
        padding-top: 0px;
      }

      @media screen and (max-width:425px) {
        padding-left: 12px !important;
      }
    }

    button {
      height: 80px;
      position: absolute;
      right: 15px;

      @media screen and (max-width:425px) {
        position: relative;
        right: 0;
        width: 60px;
        padding: 5px !important;
      }
    }

    .col-lg-12 {
      position: relative;
      background-color: #fff;
    }

    @media screen and (max-width:$breakpoint-mobile) {
      .col-2 {
        width: 60px !important;
      }

      .col-lg-12 {
        padding-left: 20px !important;
        padding-bottom: 45px !important;
        padding-top: 15px !important;
        border-radius: 16px;

      }
    }
  }
}


.report-box {

  h4 {
    color: $ilead-primary !important;
  }

  .box-hdr {

    background-color: $ilead-primary !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .w-dot {
      background-color: $post-dark-color;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

  }

  .report-month {
    background-color: $ilead-modal-content ;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 7px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 7px;

    @media screen and (max-width:$breakpoint-mobile) {
      padding: 15px 5px !important;

      h3 {
        font-size: 16px !important;
      }
    }
  }


  @media screen and (max-width:$breakpoint-mobile) {
    .col-4 {
      padding: 0px 5px !important
    }

    // h4 {margin-left:0px !important}
    >.row {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

}


// media Zoom Library
[data-rmiz-modal-content] {
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

[data-rmiz-modal-overlay=visible] {
  background-color: rgb(0, 0, 0) !important;
}

[data-rmiz-modal-overlay] {
  opacity: 0.7;
}


//  manage Goal Modal

.manage-goal-modal {
  .modal-header {
    background-color: $ilead-primary;

    h5 {
      color: #fff !important
    }
  }

  .modal-body {
    >.row {
      border: 1px solid $post-dark-color;
      border-radius: 12px;
      border-bottom-left-radius: 0;
      margin-bottom: 15px;

      .goal-edit-icon,
      .goal-delete-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $post-dark-color;
        text-align: center;
        padding-top: 10px;
      }

      .goal-count {
        color: $ilead-primary
      }

      @media screen and (max-width:$breakpoint-mobile) {
        padding-left: 5px !important;
        padding-right: 5px !important;

      }

    }



  }

}

.modal__viewmembers_popup {
  top: 60% !important;
}

// #kt_app_content {
//   padding-bottom: 0 !important;
// }

.tabbar_for_layout {
  // height: 100vh;
  overflow-y: auto;

}



.input_cstme {
  border: "0px solid #e4e7ea";
}

.bussiness-info .input_cstm:focus,
.input_cstme:focus {
  outline: none !important;
  border: 0 !important
}

.leder-info .input_cstm:focus {
  outline: none !important;
  border: 0 !important
}

.main__chat {
  width: 100% !important;
}

.chat__secondary-view {
  width: 0 !important;
}

.white-close-image .btn-close {
  background-image: url('../../../../../public/media/ilead/close-btn-2.svg') !important;
  background-repeat: no-repeat;
  background-position: center;
}

.modal-dashboard-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
  z-index: 9999; /* Make sure the overlay is above other content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-sub-dropdown{
    z-index: 9999 !important;
}



.index_detail_modal_class {
  .modal-header {
    // background-color: transparent !important;
    background: #e3371e;
    color: #000;

    .close-btn {
      right: 15px !important;
      height: 30px !important;
      position: absolute;
    }

    .btn-primary {
      top: 30px !important;
    }
  }

  .growth-listing .thumbnail-img {
    border-radius: 50% !important;
    max-width: 100%;
  }

  .leader-perform {
    .leader_box {
      height: 155px !important;

      button {
        position: relative;
        right: 0 !important;
        border: 1px dashed $blue-400;
        color: #000;
        height: 70px;
      }
    }
  }
}

.bg-dark {
  background-color: #222 !important;
}


.visitor-details {
  input,
  textarea,
  select, .multiselect-container
.searchWrapper {
    border: 0 !important;
    border-bottom: 1px solid $ilead-border-color !important;
    border-radius: 0 !important;

    &:hover {
      border-color: $ilead-primary !important
    }
    .searchBox {border-bottom:0 !important}
  }

  .modal-content {
    border-radius: 0 !important;
    height: 100vh;

    .modal-body {
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    padding-bottom:100px;
.modal-footer{
  background-color: #fff !important;
  border:0 !important;
}
      input,
      textarea,
      select,
      .css-13cymwt-control,
      .css-t3ipsp-control {
        border: 0 !important;
        border-bottom: 1px solid $ilead-border-color !important;
        border-radius: 0 !important;

        &:hover {
          border-color: $ilead-primary !important
        }
      }

      

      @media screen and (max-width:992px) {
        .row>div:nth-child(2) {
          margin-top: 1.25rem;
        }
      }
    }
  }
}
.dropzone {
  border-color: $ilead-border-color !important;
  background-color: $post-dark-color !important;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 150px;

  &:focus {
    border-color: $ilead-primary !important
  }
}
.viw-feedback {
  ul {
    li {
      button {
        color: $ilead-secondary !important;
        text-align: center !important;
        padding: 8px 25px !important;
        border-top-left-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
        border: 1px solid $ilead-primary !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }

      .active {
        background-color: $ilead-primary !important;
        color: #fff !important;
      }

      &:last-child button {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
      }
    }

    @media screen and (max-width:$breakpoint-mobile) {
      flex-wrap: nowrap;
      overflow-x: auto;
      text-wrap: nowrap;
    }

  }

  .checked {
    color: $ilead-primary !important;
  }

  .orange-strip {
    /* width: 100%; */
    background-color: #e3371e !important;
    padding: 6px 30px;
    transform: rotate(-39deg);
    position: absolute;
    left: -36px;
    top: 25px;
    /* font-size: 12px; */
    text-align: center;
    color: #fff;
  }

  .fdbk-user-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 0;
    overflow: hidden;
    position: relative;
    float: left;

    img {
      max-width: 100%;
      height: 100%;
    }
  }

  .smiley-icon {
    max-width: 40px;
  }

}

.comn-field select,
.comn-field input,
.comn-field textarea {
  border: 0 !important;
  border-bottom: 1px solid $ilead-border-color !important;
  border-radius: 0 !important;

  &:focus,
  &:active {
    border-color: $ilead-primary !important;
  }
}

.top-five-prfmr .bigi-members .member-box {
  width: 20%;
  min-width: 125px;
  margin-right: 0.625rem;
  background: #ffffff;
  height: 125px;
  position: relative;
  overflow: hidden;
  border-radius: 20px 20px 0px 20px;
}

table.leader-table tbody tr:last-child {
  border-bottom: 1px solid $ilead-secondary !important;
}

.pagination .active .page-link {
  background-color: $ilead-primary !important;
}

.hide_file {
  position: absolute;
  left: 0;
  width: 30px;
  opacity: 0;
  cursor: pointer;
  top: 0;
  z-index: 999;
}

.session-attnd {
  table>tbody tr td select {
    min-width: 150px !important;
    width: 150px !important
  }
}

.data-table-report {
  table {
    thead th {
      background-color: $ilead-primary !important;
      color: #fff !important;

      &:first-child {
        padding-left: 15px !important;
      }

      &:last-child {
        padding-left: 15px !important;
      }
    }

    tbody tr td {
      &:first-child {
        padding-left: 15px !important;
      }

      &:last-child {
        padding-right: 15px !important;
      }
    }
  }
}

.visitor-details{
  nav {
    .nav-link{
      border: 0;
      background-color: $post-dark-color !important;
      border-bottom: 1px solid $ilead-secondary !important;
      padding: 12px 0;
      color: #000;
      font-weight: 600;
      font-size: 18px;
      border-radius: 0;
  }
  .nav-link.active{
    background-color:$ilead-primary !important;
    color:#fff !important;
    border-bottom: 1px solid $ilead-separator-color !important;
    }
    }
  }
.tooltip {
  --bs-tooltip-max-width:270px !important;
  .tooltip-inner {
    background-color: $ilead-sidebar-dark-bg-color !important; color:#fff !important; 
  }
}
.bs-tooltip-top .tooltip-arrow::before {border-top-color: #000000 !important;}
.form-check input {border:1px solid $ilead-border-color !important}
.box-1 {box-shadow:rgba(0,0,0,0.1) 1px 1px 1px; -webkit-box-shadow:rgba(0,0,0,0.1) 1px 1px 1px !important;}
.toggle {
  --width: 107px;
  --height: calc(var(--width) / 3);

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  box-shadow: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle input {
  display: none;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 125px;
  height: 34px;
  border-radius: 4px;
  background-color: #9e9e9e;
  transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
  content: "";
    position: absolute;
    top: 1px;
    left: 2px;
    width: 15px;
    height: 32px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease-in-out;
}

.toggle input:checked+.slider {
  background-color: #2196F3;
  font-size: 14px;
}

.toggle input:checked+.slider::before {
  transform: translateX(calc(var(--width)));
}

.toggle .labels {
  position: absolute;
  top: 8px;
  left: 18px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: sans-serif;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 5px;
  color: #fff;
  font-size: 13px;
  opacity: 1;
  text-shadow:none;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  left: 5px;
  color: #ffffff;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked~.labels::after {
  opacity: 0;
}

.toggle input:checked~.labels::before {
  opacity: 1;
}

.all_chapter_option .css-13cymwt-control{
  background: transparent;
  border: 0;
}

.add-chat-multi-select input {
border-bottom: 0 !important;

// .searchWrapper {
// border: 0 !important;
// border-bottom: 1px solid #ccc !important;
// }
.form-select{
  border: 0 !important;
border-bottom: 1px solid #ccc !important;
}
}
.leader-point-listing {

background-color: $ilead-modal-content; height: 100px;
  .leader-points {
    border: 1px dashed #0dcaf0;
    border-radius: 50%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    min-width: 45px;
    text-align: center;

  }
           
  &:hover{
    .leader-points {
      border: 1px dashed $ilead-primary;
    }
      color:$ilead-primary;
  }

}
.visitor-details .modal-content .modal-body .checkbox-bdr  input {border:1px solid #c6c6c6 !important}
.form-check-input {border:1px solid #c6c6c6 !important}
.table-sort-asc:after {background-color: #fff !important;}
.req-acs-cont-bdr { border-top-left-radius: 50%; border-top-right-radius: 50%; border-bottom-right-radius: 50%; width:50px; height: 50px;}

.edit-user .modal-dialog{
  margin-top: 0 !important;
  margin-right: 0 !important;
}

.zoom_model-dialog{
  max-width: 99% !important;
  max-height: 90% !important;

  .modal-content{

    height: calc(100vh - 50px) !important;
    max-height: 100% !important;

  }
}


.post-ui {
  ul{
    list-style: none;

    li:hover{
    background-color:$post-dark-color !important;
    }
  }
}

.menu-item.menu-accordion .menu-arrow:after{ transform:rotateZ(0deg) !important;}
.table:not(.table-bordered).table-row-dashed tr{ background-color: #fff;}
#deletModal {
  .modal-dialog {margin-top:15%}
.delet-icon-position {
  margin-top: -81px !important;
    background-color: #fff;
    width: auto;
    position: absolute;
    padding: 20px 30px;
    border-radius: 50%;
    left: 41%;
    z-index: 1;
}
}




.delete-user-question {
  line-height: 14px;
    position: absolute;
    top: -14px;
    right: -11px;
    width: 27px;
    padding: 5px 4px;
    background-color: #ffebeb !important;
    z-index: 8;
}



.cust-pagination {
  margin-bottom: 0 !important;
  p{
    margin-bottom: 0 !important;
  }
}

.your-component-container {
  height: 90vh;
}

.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(152, 152, 152, 0.479);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  z-index: 999;
  
}

.spinner {
  z-index: 1000;
  border-color: red;
}

.css-wjh20t-MuiPagination-ul{justify-content: flex-end !important;}

.custom-agp-pagination{
  div{
    &:empty{
      display: none;
    }
  }

  .MuiTablePagination-toolbar{
    padding-left: 0 !important;
  }
}

.modal-wrap-text {
  display: block !important;
  overflow-wrap: break-word;
  max-width: 400px;
}
.no-announcement-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
  text-align: center;
  font-size: 18px;
  color: #333;
}